@font-face {
  font-family: "gm";
  src: local("gm"), url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "gr";
  src: local("gr"), url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "gsb";
  src: local("gsb"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "gb";
  src: local("gb"), url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "geb";
  src: local("geb"),
    url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

#header {
  background-image: url("./assets/headbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  font-family: 'geb';
}

#bgVideo {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}
