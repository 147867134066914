#footerDiv {
  background: linear-gradient(100.7deg, #2d2d2d 4.35%, #090909 126.82%);
  padding: 5% 5%;
  padding-bottom: 1%;
}

.mobileFooter {
  display: none;
  padding-top: 15px;
}

.footerDropDn {
  color: white;
  justify-content: space-between;
  border-bottom: 1px solid white;
  margin-top: 5%;
}

.socialImg {
  width: 30%;
  height: auto;
}

.socialImgL {
  width: 25%;
  margin-top: 50px;
  margin-bottom: 50px;
  height: auto;
}

.socialThumbs {
  width: 100%;
  padding-top: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.socialThumbsL {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-left: 25%;
}

.footerRightCircle {
  margin-top: 6px;
}

.contactIcons {
  border-radius: 100%;
  width: 10vw;
  height: 10vw;
  max-width: 50px;
  max-height: 50px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
  align-items: center;
}

.contactTitle {
  font-family: "gm";
  font-size: 3.2vw;
  display: flex;
  color: #ffffff;
  margin-bottom: 1%;
}

.contactInfo {
  font-family: "gsb";
  font-size: 3.2vw;
  display: flex;
  color: #fcc216;
  margin-bottom: 0;
}

.contactTitleL {
  font-family: "gm";
  font-size: 1.2vw;
  display: flex;
  color: #ffffff;
  margin-bottom: 1%;
}

.contactInfoL {
  font-family: "gsb";
  font-size: 1.2vw;
  display: flex;
  color: #fcc216;
  margin-bottom: 0;
}

.contactInfoDiv {
  margin-left: 15px;
}

#phNmail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10%;
}

#footerBottom {
  left: 0;
  bottom: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #5c5c5c;
}

#copyRight {
  margin-top: 2%;
  margin-bottom: 2%;
  color: #969696;
}

.dropDnText {
  font-family: "gm";
  font-size: 4vw;
  color: #ffffff;
  margin-bottom: 15px;
}

.dropDnSubText {
  font-family: "gm";
  font-size: 3.2vw;
  color: #ffffff;
  margin-left: 15px;
}

#aboutDropDn,
#faqDropDn {
  display: none;
}

.footerDropDnLabels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerDropDn:hover #aboutDropDn {
  display: block;
}

.footerDropDn:hover #faqDropDn {
  display: block;
}

.footerDropDn:hover .dropDnText {
  color: #fcc216;
}

#contactInfoL {
  margin-top: 50px;
}

#largeFooter {
  display: flex;
}

#footerLinksL {
  width: auto;
  display: flex;
  margin-left: 25%;
}

.footerLinksL {
  width: 80%;
  font-family: "gm";
  font-size: 1.2vw;
  display: flex;
  color: #8c8c8c;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footerLinksHeaderL {
  font-family: "gb";
  font-size: 1.5vw;
  display: flex;
  color: #ffffff;
  margin-bottom: 1%;
}

.linksDivL {
  margin-right: 25%;
}

@media screen and (max-width: 800px) {
  .mobileFooter {
    display: block;
  }

  #largeFooter {
    display: none;
  }
}
