#products {
  padding: 10% 0% 10% 0%;
  margin-left: 100px;
  margin-right: 100px;
  display: flex;
}

.prodImg {
  max-width: 535px;
  height: auto;
  margin: 0;
}

.carDiv {
  display: flex !important;
  width: 100%;
  align-items: center;
  flex-direction: row;
}

.prodText {
  margin-left: 8%;
  max-width: 50%;
}

#partApp {
  width: fit-content;
  height: 35px;
  text-align: center;
  background: linear-gradient(90.52deg, #6d6d6d -3.68%, #0e0e0e 111.26%);
  border-radius: 3px;
  color: white;
  padding: 5px 10px 5px 10px;
  z-index: 1;
  font-family: "gsb";
}

.btns {
  display: none;
}

.prodHeader {
  font-family: "gb";
  font-size: 40px;
  text-align: left;
}

.prodCont {
  font-family: "gm";
  text-align: left;
  color: #969696;
}

#horizontalLine {
  position: absolute;
  background-color: black;
  width: 125px;
  height: 1px;
  margin-top: 18px;
  left: 465px;
  z-index: 0;
}

#ctaBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 46px;
}

#ctaBtns:hover {
  width: 165px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 4px;
  border: none;
  padding-right: 15px;
}

#ctaRight {
  width: 52px;
  height: 46px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 4px;
  border: none;
}

#mreDets {
  font-family: "gsb";
  font-size: 14px;
  margin: 0;
}

#stepLine {
  position: absolute;
  height: 250px;
  width: 2px;
  background-color: #e9e9e9;
  z-index: -1;
  margin-left: 7.2px;
}

.slideStep {
  width: 16px;
  height: 16px;
  background-color: #e9e9e9;
  border-radius: 10px;
}

.activeStep {
  border: 3px solid #fcc216;
  background-color: white !important;
}

#stepperContainer {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#stepDots {
  height: 50%;
}

#stepperDiv {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 45px;
}

@media screen and (max-width: 1100px) {
  #products {
    padding: 0px 30px 0px 30px;
    margin-top: 15%;
    margin-bottom: 5%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .prodImg {
    max-width: 100%;
    height: auto;
    margin: 0;
    z-index: -2;
  }

  .carDiv {
    display: flex !important;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .carousel-root {
    width: 100%;
  }

  .prodText {
    margin-left: 1px;
    max-width: 100%;
  }

  #partApp {
    width: fit-content;
    height: 35px;
    text-align: center;
    background: linear-gradient(90.52deg, #6d6d6d -3.68%, #0e0e0e 111.26%);
    border-radius: 3px;
    color: white;
    padding: 5px;
    z-index: 1;
  }

  .arrowBtns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 2%;
    margin: 30px 0px 25px 0px;
  }

  .btns {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 41.83px;
    height: 41.83px;
    border-radius: 100px;
    border: none;
    margin-right: 10px;
    background: #f2f2f2;
  }

  .btns:active {
    background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  }

  #stepperDiv {
    display: none;
  }

  #mreDets {
    font-family: "gsb";
    font-size: 14px;
  }
  #horizontalLine {
    display: none;
  }

  .prodHeader {
    font-size: 6vw;
  }
}
