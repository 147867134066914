.processText {
  font-family: "gb";
  font-size: 8vw;
  margin: 0px 10px 0px 10px;
  color: black;
}

.processTextSm {
  font-family: "gb";
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 12px;
  color: black;
}

.processTextXsm {
  font-family: "gm";
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #969696;
}

#thickLine {
  width: 15vw;
  height: 1.5vw;
  background: linear-gradient(91.5deg, #ffe185 -12.63%, #f7ba00 110.68%);
  border-radius: 5px;
  margin-left: 15px;
}

#hwWeWork {
  width: 15vw;
  height: fit-content;
  image-rendering: -webkit-optimize-contrast;
  margin-right: 2vw;
}

#eightyPerc {
  width: 7vw;
  height: max-content;
  image-rendering: -webkit-optimize-contrast;
  margin-right: 2vw;
}

#tenyears {
  width: 10vw;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

#letsDiscuss {
  min-width: 150px;
  width: 3vw;
  height: 5vw;
  max-height: 50px;
  min-height: 40px;
  padding: 5px;
  border: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  margin-top: 0px;
  font-family: "gsb";
  font-size: 14px;
}

.processDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5vw 5px 5vw;
}

#researchDiv {
  justify-content: end;
  padding-right: 8vw;
}

#developDiv {
  justify-content: end;
  padding-top: 0;
  padding-bottom: 0;
}

#releaseTextImgDiv {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

#releaseDiv {
  margin-top: 25px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

#ptyp {
  width: 50%;
  align-self: center;
}

@media screen and (max-width: 800px) {
  #understandDiv {
    margin-top: 45px;
  }

  #releaseDiv {
    margin-top: 0px;
    flex-wrap: wrap;
  }

  #ptyp {
    margin-top: 15px;
  }

  #letsDiscuss {
    padding: 10px 15px 10px 15px;
    font-size: 305;
    margin-top: 15px;
  }

  #ptyp {
    width: 100%;
    align-self: center;
  }

  #thickLine {
    width: 15vw;
    height: 2vw;
    background: linear-gradient(91.5deg, #ffe185 -12.63%, #f7ba00 110.68%);
    border-radius: 5px;
    margin-left: 5px;
  }

  #hwWeWork {
    width: 25vw;
  }

  #eightyPerc {
    width: 12vw;
    height: max-content;
    image-rendering: -webkit-optimize-contrast;
    margin-right: 1vw;
  }

  #tenyears {
    width: 15vw;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
}
