#faqheadDiv {
  width: 100%;
  height: 385px;
}

#headImg {
  width: 100%;
  height: auto;
  object-fit: cover;
  height: 385px;
}

#navBg {
  background-color: black;
}

#faqHeading {
  position: absolute;
  top: 235px;
  width: 100%;
  z-index: 1;
  padding-left: 6%;
}

#faqHeadingText {
  color: white;
  font-family: "geb";
  font-size: 2.5vw;
}

#catText {
  font-family: "gb";
  font-size: 2vw;
  margin-bottom: 25px;
}

#faqContentDiv {
  width: 100%;
  height: auto;
  padding: 3% 5%;
}

#catBoxes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.catBoxImg {
  height: 32px;
  width: 32px;
}

.catBoxTitle {
  font-size: 15px;
  font-family: "gsb";
}

.catBox {
  width: 187px;
  height: 133px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #e9e9e9;
  padding-top: 15px;
  border-radius: 5px;
}

.catBox:hover {
  border: 1px solid #fcc216;
  box-shadow: 0px 0px 2px 1px #fcc216; /*any color you want*/
}

.yellowLine {
  width: 87px;
  height: 3px;
  background-color: #fcc216;
  margin: 10px;
}

.catFaqCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
