#headerDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  position: relative;
}

#headingDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

#heading {
  font-size: 65px;
  margin-bottom: 35px;
  color: white;
  font-family: "geb";
}

#headingCreative {
  font-size: 65px;
  margin-bottom: 35px;
  color: white;
  font-family: "geb";
  padding: 8px 10px 15px 10px;
  text-align: center;
  transform: rotate(-2.69deg);
  margin-right: 10px;
  background-image: url("../../assets/border.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
}

#headerText {
  width: 50%;
  font-size: 17.5px;
  color: white;
  font-family: "gm";
  text-align: center;
}

@media screen and (max-width: 800px) {
  #headerDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
  }

  #headingDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #heading {
    font-size: 35px;
    line-height: 10px;
    color: white;
    font-family: "geb";
  }

  #headingCreative {
    font-size: 38px;
    margin-bottom: 25px;
    color: white;
    font-family: "geb";
    text-align: center;
    transform: rotate(-2.69deg);
  }

  #headerText {
    width: 80%;
    font-size: 17.5px;
    color: white;
    font-family: "gm";
    text-align: center;
  }
}

#reqQ {
  width: 183px;
  height: 50px;
  border: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  margin-top: 25px;
  font-family: "gsb";
}

#rightArrow{
    margin-left: 10px;
}
