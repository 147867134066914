.upperGrid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.lowerGrid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.Grid-container-l {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(230px, auto);
  padding: 10px;
}

.Grid-container-s {
  display: none;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(230px, auto);
  padding: 10px;
}

#gridParent {
  padding: 0px 5% 60px 5%;
}

.grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: left;
}

.gridIconDiv {
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  background: linear-gradient(304.18deg, #101010 -8.11%, #636363 118.55%);
  display: flex;
  justify-content: center;
}

.gridIcon {
  width: 2.5vw;
  height: 2.5vw;
  align-self: center;
}

.gridBodyText {
  font-family: "gm";
  font-size: 14px;
  color: #969696;
}

.gridHeader {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: "gb";
}

/* Grid Borders */

.leftTop {
  border-bottom: 2px solid #dedede;
  border-right: 1px solid #dedede;
}
.rightTop {
  border-left: 1px solid #dedede;
  border-bottom: 2px solid #dedede;
}
.midTop {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-bottom: 2px solid #dedede;
}
.leftBottom {
  border-right: 1px solid #dedede;
}
.rightBottom {
  border-left: 1px solid #dedede;
}
.midBottom {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

/* On Hover */

.grid-item:hover {
  background: linear-gradient(304.18deg, #101010 -8.11%, #636363 118.55%);
  box-shadow: 0px 26.274px 38.285px -12.011px rgba(0, 0, 0, 0.15);
}

.grid-item:hover .gridHeader {
  color: white;
}

.grid-item:hover .gridIconDiv {
  background: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1100px) {
  .Grid-container-l {
    display: none;
  }

  .Grid-container-s {
    display: block;
  }

  .tabletGrid {
    border: 1px solid #dedede;
    margin-top: 15px;
  }

  /* Responsive Sizing */

  #gridParent {
    padding: 0px 5% 60px 5%;
  }

  .grid-item {
    padding: 25px;
    text-align: left;
  }

  .gridIconDiv {
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
    background: linear-gradient(304.18deg, #101010 -8.11%, #636363 118.55%);
    display: flex;
    justify-content: center;
  }

  .gridIcon {
    width: 5vw;
    height: 5vw;
    align-self: center;
  }

  .gridBodyText {
    font-family: "gm";
    font-size: 3.5vw;
    color: #969696;
    margin-bottom: 5px;
  }

  .gridHeader {
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
    font-size: 4.5vw;
    font-family: "gb";
  }
}
