.navBtn {
  /* Rectangle 4332 */
  min-width: 130px;
  min-height: 44px;
  border: none;
  font-family: "gsb";

  /* Yellow Gradient */
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3px;
}

a {
  font-family: "gm";
}

.topnav {
  overflow: visible;
  background-color: rgba(0, 0, 0, 0.297);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 25px 35px;
  text-decoration: none;
  font-size: 17px;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdownText {
  color: white;
}
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 25px 35px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  font-family: "gm";
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.297);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  padding: 25px 60px 25px 60px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: rgba(0, 0, 0, 0);
  color: #fcc216;
}

.prodDropDn {
  color: black;
  display: none;
  padding: 10px 0px 10px 0px;
}

.dropDn:hover {
  color: black !important;
}

.dropDn:hover .prodDropDn {
  display: block !important;
  color: #fcc216;
}

.dropdown-content a:hover {
  color: white;
}

.dropdown:hover .dropdown-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navItems {
  color: white;
}

@media screen and (max-width: 1100px) {
  .topnav{
    height: max-content;
  }

  .topnav a,
  .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .navBtn {
    display: none;
  }
  .topnav {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  a:hover {
    color: #fcc216 !important;
  }
  .cendrolIt {
    max-width: 200px !important;
  }

  a {
    float: none;
    display: block;
    text-align: left;
    color: white;
    text-decoration: none;
  }
}

.navLinks {
  padding-right: 10%;
}

.cendrolIt {
  max-width: 222px;
}

.dropDnContent {
  text-align: left;
  margin-right: 25px;
}

.navItems {
  color: white;
}

#myTopnav {
  padding-left: 5%;
  padding-right: 5%;
}

.rightArrow {
  max-width: 18px;
  max-height: 18px;
  margin-right: 15px;
}

.menu {
  width: 28px;
  height: 19px;
}

.rightCircle {
  position: absolute;
  right: 32px;
  margin-top: 6px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 36px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin: 10px 15px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-color: white;
  padding: 50px 15px 0px 15px;
  font-size: 1.15em;
  border-left: 6px solid #fcc216;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: black;
  font-family: "gsb";
  font-size: 20px;
  align-items: center;
  padding: 17px 0px;
}

.dividerDn {
  border-bottom: 2px solid #e9e8e8;
}
.dividerSub {
  border-bottom: 2px solid #e9e8e8;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
