#ldParentDiv {
  width: 100%;
  align-items: center;
  background-color: #fff8e1;
  padding-top: 65px;
  padding-bottom: 65px;
}

#ldInnerDiv {
  margin: auto;
  width: max-content;
  text-align: center;
}

.descText {
  font-family: "gm";
  font-size: 1.3vw;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #969696;
}

#letsDiscussBtn {
  min-width: 150px;
  width: 3vw;
  height: 5vw;
  max-height: 50px;
  min-height: 40px;
  padding: 5px;
  border: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  margin-top: 15px;
  font-family: "gsb";
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  #ldParentDiv {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  #ldInnerDiv {
    margin: auto;
    width: 90%;
    text-align: center;
  }

  .descText {
    font-family: "gm";
    font-size: 4vw;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #969696;
  }

  #letsDiscussBtn {

  }
}
