#sliderParentDiv {
  width: 100%;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-bottom: 100px;
}

#slider {
  width: 92%;
  height: auto;
}

#scrollingDiv {
  display: flex;
  flex-direction: row;
  width: 562px;
  height: 410px;
}

.card {
  min-width: 100%;
  height: 405px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(120.77deg, #fff8e1 -13.12%, #fbde85 71.23%);
  border-radius: 10px;
}

.cardPadding {
  padding-left: 15px;
  padding-right: 15px;
}

.csImgDiv {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.cardImg {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: contain;
}

.projMets {
  position: absolute;
  width: 85%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 13px -6px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
  bottom: 25px;
  border-radius: 5px;
}

.viewsNlikes {
  display: flex;
  flex-direction: row;
  margin-right: 25px;
}

.metText {
  margin: 0px;
  margin-left: 25px;
  font-family: "gb";
  font-size: 20px;
}

#smCaseSrudy {
  display: none;
}

@media screen and (max-width: 1100px) {
  #sliderParentDiv {
    display: none;
  }
  #smCaseSrudy {
    display: block;
    padding: 5% 5% 0% 5%;
  }
  .card {
    width: fit-content;
    height: auto;
  }

  .cardPadding {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 15px;
  }

  .metText {
    font-size: 3.5vw;
  }
}
