#parentDiv {
  width: 100%;
  height: fit-content;
  margin-top: 50px;
  background-color: #fff8e1;
}

#headingServices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 60px 5% 60px 5%;
  padding-right: 5%;
}

#serviceHeader {
  color: black;
  margin-bottom: 0%;
  font-size: 3vw;
  font-family: "gb";
}

#reqServQ {
  min-width: 150px;
  width: 3vw;
  height: 5vw;
  max-height: 50px;
  min-height: 40px;
  padding: 5px;
  border: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  margin-top: 0px;
  font-family: "gsb";
  font-size: 14px;
}

#tabBtns {
  width: 100%;
  display: none;
  margin-bottom: 15px;
}

#viewAll {
  width: 40%;
  height: 45px;
  min-height: 40px;
  padding: 5px;
  border: none;
  background-color: white;
  border-radius: 5px;
  margin-top: 0px;
  font-family: "gsb";
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  #gridParent {
    padding-bottom: 5px !important;
  }
  #serviceHeader {
    font-size: 5vw;
    padding-bottom: 1vw;
    text-align: center;
  }
  #headingServices {
    padding-bottom: 0px;
    display: block;
    padding-top: 35px;
  }
  #reqServQ {
    display: none;
  }
  #tabBtns {
    display: flex;
    justify-content: space-around;
    padding-top: 15px !important;
    padding: 20px;
    padding-bottom: 35px;
  }
  #tabBtns #reqServQ {
    display: block;
    width: 47%;
    height: 45px;
  }
}
