#cmsParentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 5% 0px 5%;
}

#cmsHeader {
  color: black;
  margin-bottom: 0%;
  font-size: 3vw;
  font-family: "gb";
  text-align: center;
  margin-top: 50px;
}

.custImg {
  width: 20%;
  height: auto;
  object-fit: cover;
}

.CustSayCard {
  width: 90%;
  display: flex;
  flex-direction: row;
  background-color: #fff8e1;
  margin: 5%;
}

.CustLogo {
  width: 3vw;
  height: auto;
  max-height: 50px;
  max-width: 50px;
}

.custDets {
  width: 80%;
  display: flex;
  flex-direction: row;
  padding-top: 3%;
}

.textDiv {
  padding-top: 35px;
  padding: 2vw 5% 25px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cmsMainText {
  font-family: "gm";
  font-size: 1.2vw;
  text-align: left;
}

.custTitleOrg {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 15px;
}

.custTitle {
  font-family: "gb";
  font-size: 18px;
  margin-bottom: 0;
  text-align: left;
  font-size: 1vw;
}

.custOrg {
  font-family: "gb";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #7e7e7e;
  margin-bottom: 0;
  text-align: left;
  font-size: 1vw;
}

.custImgDiv {
  width: 100%;
  height: max-content;
  max-height: 100%;
}

#nextNbackBtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 0px 50px 0px;
}

.cmsBtns {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 41.83px;
  height: 41.83px;
  border-radius: 100px;
  border: none;
  margin-right: 10px;
  background: #f2f2f2;
}

.cmsBtns:active {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.carousel .slide .custImg {
  max-width: 250px;
  max-height: 550px;
}

/* 
#cmsFade {
    content: '';
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
} */

@media screen and (max-width: 1100px) {
  #cmsHeader {
    font-size: 5vw;
    padding-bottom: 1vw;
    text-align: center;
  }

  .CustLogo {
    width: 100%;
    height: auto;
  }

  .CustImg {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  .custImgDiv {
    width: 100%;
    max-height: 250px;
  }

  .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .CustSayCard {
    flex-wrap: wrap;
    width: 90%;
  }

  .cmsMainText {
    font-family: "gm";
    font-size: 3vw;
    text-align: left;
  }

  .custTitle,
  .custOrg {
    font-size: 2.5vw;
  }

  .cmsBtns {
    width: 10vw;
    height: 10vw;
    font-size: large;
  }
}
